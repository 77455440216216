* {
  margin: 0px;
  padding: 0px;
  font-family: "Fira Sans";
  /* line-height:1.5rem */
}

p {
  line-height: 1.5rem;
}
