.carousel .slide{ background-color: rgba(0,0,0,0.7); }


.dropdown {
    position: relative;
    display: inline-block;
    font-weight: 800;
    font-size: 0.85rem;
    /* margin-right: 16px; */
    cursor: pointer;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    padding: 12px 16px;
    z-index: 1;
    border-radius: 5px;
    box-shadow: 3px 3px 12px black;
  }
  
  .dropdown:hover .dropdown-content {
    display: grid;
    line-height: 28px;
  }

  .video-react-big-play-button{
    display: none !important;
  }

  .video-react-big-play-button-left{
    display: none !important;

  }

